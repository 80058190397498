import React, { useState } from "react";
import { FormControl } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { setSearchDetails } from "../../redux/actions/searchDetails";
import { TextField, FormControlLabel, Switch } from "@mui/material";
import DateTimePicker from "../../components/DateTimePicker";
import roundToNearestHalfHour from "../../components/roundToNearestHalfHour";
function ApproveBooking({ params: { ride, handleApprove } }) {
  // console.log("ride", ride);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorSpace, setErrorSpace] = useState("");
  const { searchDetails } = useSelector((state) => state.searchDetails);

  const handleChange = (event) => {
    let newValue = event.target.value;
    // if (event.target.name === "seats") {
    //   newValue = Math.max(
    //     1,
    //     Math.min(Number(ride.available_space), Number(newValue))
    //   );
    // }
    dispatch(
      setSearchDetails({ ...searchDetails, [event.target.name]: newValue })
    );
  };
  const handleCheckboxChange = (event) => {
    dispatch(
      setSearchDetails({
        ...searchDetails,
        [event.target.name]: event.target.checked,
      })
    );
  };
  function removeDots(str) {
    return str.replace(/\./g, "");
  }
  // const arrivalSelected=(value)=>{
  //     handleChange({target:{name: 'arrivalPlace', value}})
  // }
  // const departureSelected=(value)=>{
  //     handleChange({target:{name: 'departurePlace', value}})
  // }
  // const handleSubmit  = async()=>{

  // }
  const find15PercentWithRound = (value) => {
    if (!value) return "";
    value = removeDots(value);
    return formatFare(Math.ceil((parseFloat(value) * 0.15) / 5000) * 5000);
  };
  const formatFare = (value) => {
    value = value.toString();
    const numberValue = value.replace(/\D/g, "");
    return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  const handleSubmit = () => {
    // console.log(123);
    if (
      !searchDetails.seats ||
      parseInt(searchDetails.seats) > parseInt(ride.available_space)
    ) {
      setErrorSpace(
        `Band qilinayotgan o'rindiqlar soni 1 ta dan ${ride.available_space} gacha`
      );
      return;
    }
    setLoading(true);
    handleApprove(searchDetails);
  };
  return (
    <FormControl>
      <div className="passenger-booking">
        <div className="container main" style={{ paddingBottom: 0 }}>
          {ride?.ride_start_mode === "firstPassenger" && (
            <div style={{ marginBottom: "15px" }}>
              <DateTimePicker
                type={"driver"}
                rideId={ride.ride_id}
                initialTime={
                  ride.ride_id ? roundToNearestHalfHour("timestamp") : 0
                }
                onChange={(datetime) => {
                  console.log(new Date(datetime.$d).getTime());
                  dispatch(
                    setSearchDetails({
                      ...searchDetails,
                      first_passenger_departure_datetime: new Date(
                        datetime.$d
                      ).getTime(),
                    })
                  );
                  // if (!datetime && !ride.ride_id) {
                  //   return setErrorSpace({ ...error, dateTimePicker: true });
                  // // } else {
                  // //   setError({ ...error, dateTimePicker: false });
                  // }
                  // handleChange({
                  //   target: {
                  //     name: "departureDateTime",
                  //     value: new Date(datetime.$d).getTime(),
                  //   },
                  // });
                }}
              />
            </div>
          )}
          <TextField
            id="outlined-number"
            label="*Nechta o'rindiq banq qilasiz"
            type="number"
            name="seats"
            error={!!errorSpace}
            helperText={errorSpace}
            sx={{ mb: 1 }}
            value={searchDetails.seats}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            // inputProps={{
            //   min: 1,
            //   max: parseInt(ride.available_space),
            // }}
          />
          {/* 40 => 5.000, 70 => 10.000, 150 => 20.000, 130=>*/}
          <FormControlLabel
            control={
              <Switch
                disabled={ride.front_seat}
                checked={searchDetails.frontSeat}
                onChange={handleCheckboxChange}
                name="frontSeat"
                color="primary"
              />
            }
            label={`Oldi o‘rindiq${
              searchDetails.frontSeat
                ? ` (${find15PercentWithRound(ride.fare)} so'm qimmatroq)`
                : ""
            }`}
          />
          <FormControlLabel
            control={
              <Switch
                checked={searchDetails.presenceOfWoman}
                onChange={handleCheckboxChange}
                name="presenceOfWoman"
                color="primary"
              />
            }
            sx={{ mb: 1 }}
            label="Yo'lovchilar ichida ayollar bor"
          />
          {/* <div className='marginBottom10'>
                            <FormControlLabel name='frontSeat' value={searchDetails.frontSeat} onChange={handleCheckboxChange} control={<Checkbox />} label="Oldi o‘rindiq" />
                            <FormControlLabel name='presenceOfWoman' value={searchDetails.presenceOfWoman} onChange={handleCheckboxChange} control={<Checkbox />} label="Ayollar bo'lsin!" />
                        </div> */}
          <TextField
            id="outlined-multiline-static"
            label="Haydovchiga qo'shimcha izohingiz"
            name="comment"
            value={searchDetails.comment}
            onChange={handleChange}
            size="small"
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />
          <div className="marginBottom10">
            <LoadingButton
              onClick={handleSubmit}
              loading={loading}
              variant="contained"
              color="primary"
              fullWidth
            >
              Tasdiqlash
            </LoadingButton>
            {/* <SwipeRides  searchDetails={searchDetails} setSearchDetails={setSearchDetails} handleSubmit={handleSubmit}/> */}
          </div>
        </div>
      </div>
    </FormControl>
  );
}
export default ApproveBooking;
