import { combineReducers } from "redux";
import mapReducer from './mapReducer';
import rideReducer from './rideReducer';
import rideDetailsReducer from "./rideDetails";
import searchedRides from './searchedRides'
import toggleSwipeReducer from "./toggleSwipe";
import searchDetails from "./searchDetails";
import filterReducer from "./filter";
const rootReducer = combineReducers({
    filter:filterReducer,
    searchDetails:searchDetails,
    toggleSwipe:toggleSwipeReducer,
    searchedRides:searchedRides,
    map:mapReducer,
    ride:rideReducer,
    rideDetails:rideDetailsReducer
})

export default rootReducer;