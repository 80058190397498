import "./App.css";
// import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout/Layout";
import PassengerBooking from "./pages/PassengerBooking/PassengerBooking";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import DriverAnnounce from "./pages/DriverAnnounce/DriverAnnounce";
import RatingPage from "./pages/RatingPage/RatingPage";
import YandexMap from "./components/YandexMap/YandexMap";
import RideList from "./pages/RideList/RideList";
import RatingSectionPage from "./pages/RatingSectionPage";
import Settings from "./pages/Settings/Settings";
import Support from "./pages/Support";
import Searches from "./pages/Searches";
// import RatingsSection from "./components/RatingsSection/RatingsSection";

function App() {
  // useEffect(() => {
  //   let touchStartY = 0;
  //   const handleTouchStart = (event) => {
  //     touchStartY = event.touches[0].clientY;
  //   };

  //   const handleTouchMove = (event) => {
  //     const touchEndY = event.changedTouches[0].clientY;
  //     if (Math.abs(touchStartY - touchEndY) > 10) {
  //       // Adjust threshold based on your needs
  //       event.preventDefault();
  //     }
  //   };

  //   document.addEventListener("touchstart", handleTouchStart, {
  //     passive: false,
  //   });
  //   document.addEventListener("touchmove", handleTouchMove, { passive: false });

  //   return () => {
  //     document.removeEventListener("touchstart", handleTouchStart);
  //     document.removeEventListener("touchmove", handleTouchMove);
  //   };
  // }, []);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="passenger-booking" element={<PassengerBooking />}></Route>
        <Route path="driver-announce" element={<DriverAnnounce />}></Route>
        <Route path="ride-list" element={<RideList />}></Route>
        <Route path="rating-page" element={<RatingPage />}></Route>
        <Route path="searches-monitoring" element={<Searches />}></Route>
        <Route
          path="rating-section-page"
          element={<RatingSectionPage />}
        ></Route>
        {/* <Route path="rating-section" element={<RatingsSection />}></Route> */}
        <Route path="settings" element={<Settings />}></Route>
        <Route path="support" element={<Support />}></Route>
        <Route path="test" element={<YandexMap />}></Route>
        <Route path="*" element={<NotFoundPage />}></Route>
      </Route>
    </Routes>
  );
}

export default App;
