import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Box,
  Rating,
  Button,
  TextField,
} from "@mui/material";
import httpService from "../../services/http.service";
import StackAlert from "../../components/StackAlert";
const tg = window.Telegram?.WebApp;

const RatingPage = () => {
  const [value, setValue] = useState(0); // Default rating
  const [comment, setComment] = useState(""); // For storing user comments
  const [ratingResponse, setRatingResponse] = useState({});
  useEffect(() => {
    tg.ready();
    // const params = new URLSearchParams(window.location.search);
    // // const web_app_query_id = tgParams.get('query_id');
    // const passenger_id = parseFloat(params.get("passenger_id"));
    // const ride_id = parseFloat(params.get("ride_id"));
    // const driver_id = parseFloat(params.get("driver_id"));
    // const passenger_telegram_message_id = parseFloat(
    //   params.get("passenger_telegram_message_id") ?? 0
    // );
    // const driver_telegram_message_id = parseFloat(
    //   params.get("driver_telegram_message_id") ?? 0
    // );
    // console.log({ passenger_telegram_message_id });
    // console.log({ driver_telegram_message_id });
  }, []);
  const handleSubmit = async (event) => {
    // event.preventDefault();
    // const tgParams = new URLSearchParams(tg.initData);
    const params = new URLSearchParams(window.location.search);
    // const web_app_query_id = tgParams.get('query_id');
    const passenger_id = parseFloat(params.get("passenger_id"));
    const ride_id = parseFloat(params.get("ride_id"));
    const driver_id = parseFloat(params.get("driver_id"));
    const passenger_telegram_message_id = parseFloat(
      params.get("passenger_telegram_message_id") ?? 0
    );
    const driver_telegram_message_id = parseFloat(
      params.get("driver_telegram_message_id") ?? 0
    );

    // console.log({ passenger_id });
    // console.log({ ride_id });
    // console.log({ value });
    // console.log({ comment });
    // console.log({ driver_id });
    // console.log({ passenger_telegram_message_id });
    // console.log({ driver_telegram_message_id });
    try {
      const res = await httpService.rateRide({
        passenger_id,
        ride_id,
        value,
        comment,
        driver_id,
        passenger_telegram_message_id,
        driver_telegram_message_id,
      });
      if (res.ok) {
        setRatingResponse({
          status: "success",
          message: "Izoh muvaffaqiyatli qoldirildi!",
        });
        setTimeout(() => {
          tg.close();
        }, 3000);
      }
    } catch (e) {
      if (
        e.message?.includes(
          'duplicate key value violates unique constraint "ratings_ride_id_from_user_to_user_key"'
        )
      ) {
        setRatingResponse({
          status: "error",
          message: "Bu safarga allaqachon izoh qoldirilgan",
        });
      } else if (e.message?.includes("rating limit exceeded")) {
        setRatingResponse({
          status: "error",
          message: "Bir oyda bir haydovchiga faqat 5 ta izoh qoldirish mumkin",
        });
      } else {
        await httpService.sendToSupport({
          message: "frontend rateRide " + e.message,
          Stack: e.stack ? e.stack : "",
        });
        setRatingResponse({
          status: "error",
          message: e.message,
        });
      }
      // console.log(e.error);
      // tg.close();
    }
    // const passenger_id=2;
    // const ride_id=56
    // tg.answerWebAppQuery()
    // tg.sendData(JSON.stringify({action_type:'rate_ride'}))
  };

  return (
    <Paper
      elevation={6}
      sx={{
        p: 3,
        backgroundColor: "var(--tg-theme-bg-color)",
        backgroundImage: "none",
      }}
    >
      {Object.keys(ratingResponse).length ? (
        <StackAlert
          status={ratingResponse.status}
          message={ratingResponse.message}
        />
      ) : (
        <></>
      )}
      <Typography component="h1" variant="h5" align="center">
        Haydovchiga baho bering
      </Typography>
      <Box
        component="fieldset"
        mb={3}
        borderColor="transparent"
        textAlign="center"
      >
        <Typography component="legend">Sizning reytingiz</Typography>
        <Rating
          name="driver-rating"
          value={value}
          size="large"
          precision={0.5}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        />
      </Box>
      <TextField
        label="izoh"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        margin="normal"
      />
      <Button
        disabled={!value}
        type="submit"
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        sx={{ mt: 2 }}
      >
        Reytingni yuborish
      </Button>
    </Paper>
  );
};

export default RatingPage;
