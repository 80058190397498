import { useState, useEffect, useCallback } from "react";
import "./DriverAnnounce.css";
import { Button, FormControl, TextField } from "@mui/material";
import DateTimePicker from "../../components/DateTimePicker";
import SwipeableMap from "../../components/SwipeableMap/SwipeableMap";
import {
  Switch,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import httpService from "../../services/http.service";
import { loadMapScript } from "../../components/loadScript";
import roundToNearestHalfHour from "../../components/roundToNearestHalfHour";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const tg = window.Telegram?.WebApp;

function DriverAnnounce() {
  const [rideId, setRideId] = useState(0);
  const [chRAvailableSpace, setChRAvailableSpace] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    dateTimePicker: false,
    availableSpace: "",
    fare: "",
  });
  const [formData, setFormData] = useState({
    departurePlace: {},
    departureDateTime: roundToNearestHalfHour("timestamp"),
    availableSpace: 4,
    arrivalPlace: {},
    fare: 0,
    duration: "",
    distance: "",
    comment: "",
    autoBooking: true,
    rideStartMode: "firstPassenger",
  });
  const [administrativeAreaLevel, setAdministrativeAreaLevel] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ride_id = params.get("ride_id");
    if (ride_id) setLoading(true);
    setRideId(ride_id);
    loadMapScript();
  }, []);

  useEffect(() => {
    tg?.ready();
    if (rideId) {
      console.log("load ride", rideId);
      const getRide = async () => {
        try {
          const ride = await httpService.getRide(rideId);
          const {
            comment,
            fare,
            auto_booking,
            departure_place_address,
            arrival_place_address,
            departure_datetime,
            available_space,
            ride_start_mode, // Assume this field is available in the API response
          } = ride;
          setChRAvailableSpace(available_space);
          setFormData({
            ...formData,
            comment,
            fare,
            autoBooking: auto_booking === "false" ? false : true,
            departurePlace: { formatted_address: departure_place_address },
            arrivalPlace: { formatted_address: arrival_place_address },
            departureDateTime: parseInt(departure_datetime),
            availableSpace: parseInt(available_space),
            rideStartMode: ride_start_mode || "firstPassenger", // Use the ride start mode from API or default
          });
          setLoading(false);
        } catch (e) {
          await httpService.sendToSupport({
            message: "frontend " + e.message,
            Stack: e.stack ? e.stack : "",
          });
          console.log(e);
        }
      };
      getRide();
    }
  }, [rideId]);

  const handleChange = (event) => {
    let changeable = true;
    let newValue = event.target.value;
    if (rideId) {
      switch (event.target.name) {
        case "availableSpace":
          if (newValue && parseInt(newValue) > chRAvailableSpace) {
            newValue = chRAvailableSpace;
          }
          break;
        default:
          break;
      }
    }
    if (changeable) {
      setFormData({ ...formData, [event.target.name]: newValue });
    }
  };

  const arrivalSelected = (value) => {
    const {
      area_level_1: arrival_area_level_1,
      area_level_2: arrival_area_level_2,
      ...otherProps
    } = value;
    setAdministrativeAreaLevel((prev) => ({
      ...prev,
      arrival_area_level_1,
      arrival_area_level_2,
    }));
    handleChange({ target: { name: "arrivalPlace", value: otherProps } });
  };

  const departureSelected = (value) => {
    const {
      area_level_1: departure_area_level_1,
      area_level_2: departure_area_level_2,
      ...otherProps
    } = value;
    setAdministrativeAreaLevel((prev) => ({
      ...prev,
      departure_area_level_1,
      departure_area_level_2,
    }));
    handleChange({ target: { name: "departurePlace", value: otherProps } });
  };

  const validateF = () => {
    const availableSpace =
      !formData.availableSpace || formData.availableSpace < 0
        ? "Yo'lovchilar soni 0 dan katta bo'lishi kerak"
        : "";
    const fare =
      !formData.fare || parseFloat(removeDots(formData.fare)) < 10000
        ? "Eng kam yo'l haqi 10.000 so'm"
        : "";
    setError({
      ...error,
      fare,
      availableSpace,
    });
    return !!availableSpace || !!fare;
  };

  const removeDots = (str) => {
    return str.replace(/\./g, "");
  };

  const handleSubmit = async () => {
    if (validateF()) return;
    if (!rideId) {
      calculateRoute(formData.departurePlace, formData.arrivalPlace);
    } else {
      try {
        await httpService.editRide({ ride_id: rideId, ...formData });
        tg.close();
      } catch (e) {
        await httpService.sendToSupport({
          message: "frontend editRide " + e.message,
          Stack: e.stack ? e.stack : "",
        });
        tg.close();
      }
    }
  };

  useEffect(() => {
    if (formData.duration && formData.distance) {
      const makeRequest = async () => {
        const {
          departurePlace: {
            formatted_address: departure_place_address,
            location: {
              lat: departure_location_lat,
              lng: departure_location_lng,
            },
          },
          arrivalPlace: {
            formatted_address: arrival_place_address,
            location: { lat: arrival_location_lat, lng: arrival_location_lng },
          },
        } = formData;
        const params = new URLSearchParams(window.location.search);
        const user_id = parseFloat(params.get("user_id"));
        const telegram_id = parseFloat(params.get("telegram_id"));

        try {
          await httpService.insertSearch({
            telegram_id,
            search_id: 0,
            user_id,
            departure_place_address,
            departure_location_lng,
            departure_location_lat,
            arrival_place_address,
            arrival_location_lng,
            arrival_location_lat,
            role: "driver",
            ...administrativeAreaLevel,
          });
        } catch (e) {
          await httpService.sendToSupport({
            message: "frontend " + e.message,
            Stack: e.stack ? e.stack : "",
          });
          console.log(e);
        }
        tg.sendData(JSON.stringify({ action_type: "CREATE", order: formData }));
      };
      makeRequest();
    }
  }, [formData, administrativeAreaLevel]);

  const formatFare = (value) => {
    const numberValue = value.replace(/\D/g, "");
    return numberValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleFareChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: formatFare(event.target.value),
    });
  };

  const handleAutoBookingChange = (event) => {
    setFormData({ ...formData, autoBooking: event.target.checked });
  };

  const handleRideStartModeChange = (event) => {
    setFormData({
      ...formData,
      rideStartMode: event.target.checked ? "firstPassenger" : "driverSelected",
    });
  };

  const convertToTimestamp = (timeString) => {
    let hours = 0,
      minutes = 0;
    let timeParts;
    if (
      (timeParts = timeString.match(/(\d+)\s*hours?\s+(\d+)\s*mins?/)) ||
      (timeParts = timeString.match(/(\d+)\s*hours?\s+(\d+)\s*mins?/))
    ) {
      hours = parseInt(timeParts[1], 10);
      minutes = parseInt(timeParts[2], 10);
    } else if ((timeParts = timeString.match(/(\d+)\s*daqiq[a]?/))) {
      hours = 0;
      minutes = parseInt(timeParts[1], 10);
    }
    if (!hours && !minutes) {
      throw new Error("Invalid time string format");
    }
    const totalMilliseconds = (hours * 60 * 60 + minutes * 60) * 1000;
    return totalMilliseconds;
  };

  const calculateRoute = useCallback(async (departurePlace, arrivalPlace) => {
    if (!window.google) return;
    const request = {
      origin: departurePlace.location,
      destination: arrivalPlace.location,
      travelMode: "DRIVING",
    };
    const directions = new window.google.maps.DirectionsService();
    const result = await directions.route(request);
    const { distance, duration } = result.routes[0].legs[0];
    setFormData((currentFormData) => ({
      ...currentFormData,
      duration: convertToTimestamp(duration.text),
      distance: parseInt(distance.text),
    }));
  }, []);

  return !loading ? (
    <FormControl>
      <div className="driver-announce">
        <div className="container main">
          <div className="marginBottom10">
            <SwipeableMap
              swipeType="departure"
              data-cy="departure"
              initialValue={rideId ? formData.departurePlace : ""}
              label="Jo‘nash joyi"
              placeSelected={departureSelected}
            />
          </div>
          <div className="marginBottom10">
            <SwipeableMap
              swipeType="arrival"
              initialValue={rideId ? formData.arrivalPlace : ""}
              label="Yetib kelish joyi"
              placeSelected={arrivalSelected}
            />
          </div>
          {formData.rideStartMode !== "firstPassenger" && (
            <div>
              <DateTimePicker
                type={"driver"}
                rideId={rideId}
                initialTime={rideId ? formData.departureDateTime : 0}
                onChange={(datetime) => {
                  if (!datetime && !rideId) {
                    return setError({ ...error, dateTimePicker: true });
                  } else {
                    setError({ ...error, dateTimePicker: false });
                  }
                  handleChange({
                    target: {
                      name: "departureDateTime",
                      value: new Date(datetime.$d).getTime(),
                    },
                  });
                }}
              />
            </div>
          )}
          <div className="marginBottom20">
            <FormControlLabel
              control={
                <Switch
                  checked={formData.rideStartMode === "firstPassenger"}
                  onChange={handleRideStartModeChange}
                />
              }
              label={
                formData.rideStartMode === "firstPassenger"
                  ? "Yo'lovchi vaqtiga qarab yuraman"
                  : "Ketish vaqtini o'zim belgilayman"
              }
            />
          </div>
          <div className="marginBottom20">
            <TextField
              id="outlined-number"
              label="Nechta yo'lovchi olasiz?"
              type="number"
              name="availableSpace"
              error={!!error.availableSpace}
              helperText={error.availableSpace}
              value={formData.availableSpace}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ max: rideId ? chRAvailableSpace : 100 }}
            />
          </div>
          <div className="marginBottom20">
            <TextField
              id="outlined-number"
              label="Yo'l haqi(Har bir yo'lovchi uchun)?"
              type="number"
              name="fare"
              error={!!error.fare}
              helperText={error.fare}
              value={formData.fare}
              onChange={handleFareChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="marginBottom10">
            <TextField
              id="outlined-multiline-static"
              label="Izoh"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              size="small"
              multiline
              rows={4}
            />
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={formData.autoBooking}
                onChange={handleAutoBookingChange}
              />
            }
            label={
              formData.autoBooking
                ? "Yo'lovchilar avtomatik olinsin"
                : "Yo'lovchilarni har birini o'zim qabul qilaman"
            }
          />
        </div>
        <div>
          <Button
            disabled={
              !rideId &&
              (!formData.departurePlace.location ||
                !formData.arrivalPlace.location ||
                error.dateTimePicker)
            }
            size="large"
            sx={{ borderRadius: 0 }}
            onClick={handleSubmit}
            variant="contained"
          >
            Buyurtma berish
          </Button>
        </div>
      </div>
    </FormControl>
  ) : (
    <Box
      sx={{
        display: "fixed",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default DriverAnnounce;
