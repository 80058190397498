import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Paper } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn"; // For departure
import FlagIcon from "@mui/icons-material/Flag";

const SearchedRidePlaces = ({ openQuickSearchedRides, type }) => {
  const { items } = useSelector((state) => state.searchedRides);
  const [searchedRides, setSearchDetails] = useState([]);
  useEffect(() => {
    if (items.length) {
      if (type !== "couple") {
        const newItems = items?.reduce((acc, item) => {
          console.log(item);
          if (
            !acc.find(
              (i) => i.formatted_address === item.departure_place_address
            )
          ) {
            acc.push({
              formatted_address: item.departure_place_address,
              location: {
                lng: item.departure_location.x,
                lat: item.departure_location.y,
              },
              area_level_1: item.departure_area_level_1,
              area_level_2: item.departure_area_level_2,
            });
          }
          if (
            !acc.find((i) => i.formatted_address === item.arrival_place_address)
          ) {
            acc.push({
              formatted_address: item.arrival_place_address,
              location: {
                lng: item.arrival_location.x,
                lat: item.arrival_location.y,
              },
              area_level_1: item.arrival_area_level_1,
              area_level_2: item.arrival_area_level_2,
            });
          }
          return acc;
        }, []);
        setSearchDetails(newItems);
      } else {
        setSearchDetails(items);
      }
    }
  }, [items, type]);
  return (
    <>
      {searchedRides ? (
        <Box style={{ userSelect: "none" }}>
          {searchedRides.map((ride, index) =>
            type === "couple" ? (
              <Paper
                onClick={() => {
                  openQuickSearchedRides(ride);
                }}
                key={index}
                elevation={3}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "var(--tg-theme-bg-color)",
                  backgroundImage: "none",
                  p: 2,
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box display="flex" alignItems="center">
                  <LocationOnIcon sx={{ mr: 1, color: "primary.main" }} />
                  <Typography variant="body1">
                    {ride.departure_place_address}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FlagIcon sx={{ mr: 1, color: "secondary.main" }} />
                  <Typography variant="body1">
                    {ride.arrival_place_address}
                  </Typography>
                </Box>
              </Paper>
            ) : (
              <Paper
                onClick={() => {
                  openQuickSearchedRides(ride);
                }}
                key={index}
                elevation={3}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "var(--tg-theme-bg-color)",
                  backgroundImage: "none",
                  p: 2,
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box display="flex" alignItems="center">
                  {type === "departure" ? (
                    <LocationOnIcon sx={{ mr: 1, color: "primary.main" }} />
                  ) : (
                    <FlagIcon sx={{ mr: 1, color: "secondary.main" }} />
                  )}
                  <Typography variant="body1">
                    {ride.formatted_address}
                  </Typography>
                </Box>
              </Paper>
            )
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default SearchedRidePlaces;
