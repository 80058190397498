import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
const StackAlert = ({ status, message }) => {
  return (
    <Stack spacing={2} sx={{ marginBottom: 2 }}>
      {status === "warning" && <Alert severity="warning">{message}</Alert>}
      {status === "success" && <Alert severity="success">{message}</Alert>}
      {status === "error" && <Alert severity="error">{message}</Alert>}
    </Stack>
  );
};

export default StackAlert;
