function roundToNearestHalfHour(type) {
  const date = new Date();
  const minutes = date.getMinutes();
  const roundingTo = minutes >= 30 ? 0 : 30;
  const hoursToAdd = minutes >= 30 ? 1 : 0;

  date.setHours(date.getHours() + hoursToAdd);
  date.setMinutes(roundingTo, 0, 0); // Sets minutes, seconds, and milliseconds
  if (type === "timestamp") {
    return date.getTime();
  }
  return date;
}

export default roundToNearestHalfHour;
