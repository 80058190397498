import httpService from "../services/http.service";
const loadMapScript = () => {
  const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&v=weekly&language=uz&region=UZ&callback=initMap`;
  const existingScript = Array.from(document.scripts).some(
    (script) => script.src === src && script.async
  );
  if (existingScript) {
    return;
  }

  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.onerror = async function (e) {
    await httpService.sendToSupport({
      message: "frontend Failed to load the Google Maps script!",
    });
    console.log("Failed to load the Google Maps script!");
  };
  window.initMap = function () {
    if (!window.google || !window.google.maps) {
      console.log("Google Maps API failed to load.");
      this.sendToSupport({
        message: "frontend Google Maps API failed to load.",
      });
      return;
    }
    console.log("Map JS API is loaded and available");
  };
  document.head.appendChild(script);
};
export { loadMapScript };
