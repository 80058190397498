import React, { useState, useEffect } from "react";
import { Typography, Paper, Button, TextField } from "@mui/material";
import httpService from "../services/http.service";
import StackAlert from "../components/StackAlert";
const tg = window.Telegram?.WebApp;

const Support = () => {
  const [text, setText] = useState("");
  const [supportResponse, setSupportResponse] = useState({});
  useEffect(() => {
    tg.ready();
  }, []);
  const handleSubmit = async (event) => {
    const params = new URLSearchParams(window.location.search);
    // const user_id = parseFloat(params.get("user_id"));
    const telegram_id = parseFloat(params.get("telegram_id"));
    if (!telegram_id) return;
    try {
      const res = await httpService.sendToSupport({
        telegram_id,
        text,
      });
      if (res.ok) {
        setSupportResponse({
          status: "success",
          message: "Izoh muvaffaqiyatli qoldirildi!",
        });
        setTimeout(() => {
          tg.close();
        }, 3000);
      }
    } catch (e) {
      await httpService.sendToSupport({
        message: "frontend " + e.message,
        Stack: e.stack ? e.stack : "",
      });
      setSupportResponse({
        status: "error",
        message: e.message,
      });
    }
  };

  return (
    <Paper
      elevation={6}
      sx={{
        p: 3,
        backgroundColor: "var(--tg-theme-bg-color)",
        backgroundImage: "none",
      }}
    >
      {Object.keys(supportResponse).length ? (
        <StackAlert
          status={supportResponse.status}
          message={supportResponse.message}
        />
      ) : (
        <></>
      )}
      <Typography component="h1" variant="h5" align="center">
        Qo'llab-quvvatlash xizmatiga yozing
      </Typography>
      <TextField
        label="izoh"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        value={text}
        onChange={(e) => setText(e.target.value)}
        margin="normal"
      />
      <Button
        disabled={!text}
        type="submit"
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        sx={{ mt: 2 }}
      >
        Jo'natish
      </Button>
    </Paper>
  );
};

export default Support;
