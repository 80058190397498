const initialState = {
    items:[],
    loading: false,
    error:null
};

const searchedRides = (state = initialState, action)=>{
    switch(action.type){
        case 'FETCH_SEARCHED_RIDES_REQUEST':
            return {...state, loading:true, error:null}
        case 'FETCH_SEARCHED_RIDES_SUCCESS':
                return {...state, loading:false, items:action.payload}
        case 'FETCH_SEARCHED_RIDES_FAILURE':
            return {...state, loading:false, error:action.payload}
        default:
            return state;
    }
}
export default searchedRides;