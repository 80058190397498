import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
const FooterButtons = ({ enableScrollToTop, closeButtonPos, toggle }) => {
  const scrollToElement = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      //   block: "start",
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: enableScrollToTop ? "space-between" : "flex-start",
        position: closeButtonPos === "bottom" ? "fixed" : "relative",
        left: "10px",
        bottom: closeButtonPos === "bottom" ? "30px" : "auto",
        right: enableScrollToTop ? "10px" : "initial",
        zIndex: 10,
      }}
    >
      <div>
        <Button
          onClick={() => toggle()}
          variant="contained"
          color="secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%", // Makes the button circular
            minWidth: "auto", // Removes the minimum width
            padding: "10px", // Adjust padding to your preference
            lineHeight: 1, // Adjust line height for proper icon alignment
          }}
        >
          <ArrowBackIcon />
        </Button>
      </div>
      {enableScrollToTop && (
        <div>
          <Button
            onClick={scrollToElement}
            variant="contained"
            color="secondary"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%", // Makes the button circular
              minWidth: "auto", // Removes the minimum width
              padding: "10px", // Adjust padding to your preference
              lineHeight: 1, // Adjust line height for proper icon alignment
            }}
          >
            <ArrowUpward />
          </Button>
        </div>
      )}
    </div>
  );
};

export default FooterButtons;
