const initialState = {
    isRideDetailsOpen: false
}
const rideDetailsReducer = (state = initialState, action)=>{
    switch(action.type){
        case 'TOGGLE_RIDE_DETAILS_SWIPE': 
            return {
                ...state,
                isRideDetailsOpen: !state.isRideDetailsOpen
            }
        default: 
            return state;
    }
}

export default rideDetailsReducer;