// import React, { useEffect, useRef, useState } from "react";
// import {
//   YMaps,
//   Map,
//   Placemark,
//   SearchControl,
//   TypeSelector
// } from "@pbe/react-yandex-maps";
//     import Autocomplete from "@mui/material/Autocomplete";
// import TextField from "@mui/material/TextField";

// const API_KEY = "11d629f1-da1d-49a0-9302-49ff5bf788e9test";

// const center = [47.2313, 39.7233];

// const images = [...Array(5)].map((n, i) => {
//   const letter = String.fromCharCode(i + 97);
//   return `https://img.icons8.com/ios-filled/2x/marker-${letter}.png`;
// });

// const YandexMap = (props) => {
//     const [inputValue, setInputValue] = useState('');
//     const [options, setOptions] = useState([]);

//     useEffect(() => {
//       if (inputValue === '') {
//         setOptions([]);
//         return undefined;
//       }

//       // Replace 'YOUR_API_KEY' with your actual Yandex Maps API key
//       const fetchLocations = async () => {
//         const response = await fetch(`https://geocode-maps.yandex.ru/1.x/?format=json&apikey=YOUR_API_KEY&geocode=${inputValue}`);
//         const data = await response.json();
//         const locations = data.response.GeoObjectCollection.featureMember.map(item => item.GeoObject);
//         setOptions(locations);
//       };

//       fetchLocations();
//     }, [inputValue]);

//     return (
//       <Autocomplete
//         freeSolo
//         inputValue={inputValue}
//         onInputChange={(event, newInputValue) => {
//           setInputValue(newInputValue);
//         }}
//         options={options.map(option => option.metaDataProperty.GeocoderMetaData.text)}
//         renderInput={(params) => <TextField {...params} label="Search location" />}
//       />
//     );
//     };

//     export default YandexMap;

import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
// import parse from 'autosuggest-highlight/parse';
import Box from "@mui/material/Box";
// const API_KEY = "11d629f1-da1d-49a0`-9302-49ff5bf788e9";
const API_KEY = "cf133397-4e92-478e-9c22-b49993ff1820"; //new for obidov.bekzod94@gmail.com

const bbox = "55.9982,37.1923~56.76,37.842";

const YandexMap = (props) => {
  const searchTimeoutId = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }
    clearTimeout(searchTimeoutId.current);
    searchTimeoutId.current = setTimeout(() => {
      const fetchLocations = async () => {
        try {
          const response = await fetch(
            `https://suggest-maps.yandex.ru/v1/suggest?apikey=${API_KEY}&text=${inputValue}&bbox=${bbox}&lang=uz`
          );
          // const response = await fetch(`https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${API_KEY}&geocode=${inputValue}&bbox=${bbox}&lang=uz_UZ`);
          const { results } = await response.json();
          // console.log("results", results);
          // const founder = [...results];
          const locations = results?.filter(
            (location, idx1) =>
              !results.find(
                (item, idx2) =>
                  item.title.text === location.title.text && idx1 !== idx2
              )
          );
          // console.log("locations", locations);
          // const locations = data.response.GeoObjectCollection.featureMember.map(item => item.GeoObject);
          setOptions(locations ?? []);
        } catch (e) {
          console.log(e);
        }
      };

      fetchLocations(searchTimeoutId.current);
    }, 300);
    return () => clearTimeout();
  }, [inputValue, searchTimeoutId]);

  return (
    <Autocomplete
      id="yandex-map"
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.title.text +
            (option.subtitle ? " " + option.subtitle.text : "")
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="Joy tanlanmagan"
      onChange={(event, newValue) => {
        // console.log("newValue", newValue);
        // setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        // handlePlaceSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        // console.log('onInputChange',newInputValue)
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <div className="autocompleteTextfield">
          <TextField
            inputRef={inputRef}
            {...params}
            label={"label"}
            fullWidth
          />
        </div>
      )}
      renderOption={(props, option) => {
        // console.log(props)
        // console.log(option)
        // const matches =
        //   option.structured_formatting.main_text_matched_substrings || [];

        // const parts = parse(
        //   option.structured_formatting.main_text,
        //   matches.map((match) => [match.offset, match.offset + match.length]),
        // );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Box component="span" sx={{ fontWeight: "regular" }}>
                  {option.title.text}
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {option.subtitle?.text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default YandexMap;
