import React, { useEffect, useRef, useState } from "react";
import "./PassengerBooking.css";
import { FormControl, Button } from "@mui/material";
import DateTimePicker from "../../components/DateTimePicker";
import SwipeableMap from "../../components/SwipeableMap/SwipeableMap";
// import SwipeRides from "../../components/SwipeRides/SwipeRides";
import { useDispatch, useSelector } from "react-redux";
import { setSearchDetails } from "../../redux/actions/searchDetails";
import SearchedRidePlaces from "../../components/SearchedRidePlaces/SearchedRidePlaces";
// import { toggleSearchedRidesSwipe } from "../../redux/actions/toggleSwipe";
// import Swipe from "../../components/Swipes/Swipe";
// import RideList from "../RideList/RideList";
import { useNavigate } from "react-router-dom";
import roundToNearestHalfHour from "../../components/roundToNearestHalfHour";
function PassengerBooking() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(0);
  const [telegramId, setTelegramId] = useState(0);
  const { searchDetails } = useSelector((state) => state.searchDetails);
  const useEffectSearchDetails = useRef(false);
  // const [searchDetails, setSearchDetails] = useState({

  // })
  useEffect(() => {
    if (useEffectSearchDetails.current) {
      useEffectSearchDetails.current = false;
    } else {
      useEffectSearchDetails.current = true;
      const params = new URLSearchParams(window.location.search);
      const user_id = parseFloat(params.get("user_id"));
      const telegram_id = parseFloat(params.get("telegram_id"));
      // console.log({ user_id, telegram_id });
      setUserId(user_id);
      setTelegramId(telegram_id);
      // console.log({ searchDetails });
      dispatch(
        setSearchDetails({
          ...searchDetails,
          first_passenger_departure_datetime:
            roundToNearestHalfHour("timestamp"),
          user_id,
          telegram_id,
        })
      );
    }
  }, [dispatch, searchDetails]);

  const handleChange = (event) => {
    dispatch(
      setSearchDetails({
        ...searchDetails,
        [event.target.name]: event.target.value,
      })
    );
  };
  // const handleCheckboxChange = (event) => {
  //     setSearchDetails({ ...searchDetails, [event.target.name]: event.target.checked });
  // };
  const arrivalSelected = (value) => {
    console.log("arrivalSelected", value);
    handleChange({ target: { name: "arrivalPlace", value } });
  };
  const departureSelected = (value) => {
    console.log("departureSelected", value);
    handleChange({ target: { name: "departurePlace", value } });
  };
  // const handleSubmit = async () => {};
  const openQuickSearchedRides = async (ride) => {
    dispatch(
      setSearchDetails({
        ...searchDetails,
        departurePlace: {
          formatted_address: ride.departure_place_address,
          location: {
            lat: ride.departure_location.y,
            lng: ride.departure_location.x,
          },
          area_level_1: ride.departure_area_level_1 ?? null,
          area_level_2: ride.departure_area_level_2 ?? null,
        },
        departureDateTime: new Date().getTime(),
        arrivalPlace: {
          formatted_address: ride.arrival_place_address,
          location: {
            lat: ride.arrival_location.y,
            lng: ride.arrival_location.x,
          },
          area_level_1: ride.arrival_area_level_1 ?? null,
          area_level_2: ride.arrival_area_level_2 ?? null,
        },
        search_id: ride.id,
        temp_search_id: 0,
      })
    );
    const queryParams = new URLSearchParams({
      user_id: userId,
      telegram_id: telegramId,
    });
    navigate(`/ride-list?${queryParams}`);
    // dispatch(toggleSearchedRidesSwipe());
  };
  return (
    <>
      <FormControl>
        <div className="passenger-booking">
          <div className="container main">
            <div className="marginBottom17">
              <SwipeableMap
                swipeType="departure"
                label="Jo‘nash joyi"
                placeSelected={departureSelected}
              />
            </div>
            <div className="marginBottom10">
              <SwipeableMap
                swipeType="arrival"
                label="Yetib kelish joyi"
                placeSelected={arrivalSelected}
              />
            </div>
            <div className="marginBottom10">
              <DateTimePicker
                type={"passenger"}
                onChange={(datetime) =>
                  handleChange({
                    target: {
                      name: "departureDateTime",
                      value: new Date(datetime.$d).getTime(),
                    },
                  })
                }
              />
            </div>
            {/* <div>
                        <TextField
                            id="outlined-number"
                            label="Odam soni"
                            type="number"
                            name='reservingSpace'
                            value={searchDetails.reservingSpace}
                            onChange={handleChange} 
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        </div> */}
            {/* <div>
                            <FormControlLabel name='frontSeat' value={searchDetails.frontSeat} onChange={handleCheckboxChange} control={<Checkbox />} label="Oldi o‘rindiq" />
                            <FormControlLabel name='presenceOfWoman' value={searchDetails.presenceOfWoman} onChange={handleCheckboxChange} control={<Checkbox />} label="Ayollar bo'lsin!" />
                        </div> */}
            {/* <div className='marginBottom10'>
                        <TextField
                            id="outlined-multiline-static"
                            label="Izoh"
                            name='comment'
                            value={searchDetails.comment}
                            onChange={handleChange} 
                            size='small'
                            multiline
                            rows={4}
                        />
                        </div> */}
            <div className="marginBottom20">
              <Button
                size="large"
                sx={{ borderRadius: 0, marginBottom: "20px" }}
                onClick={() => {
                  const queryParams = new URLSearchParams({
                    user_id: userId,
                    telegram_id: telegramId,
                  });
                  navigate(`/ride-list?${queryParams}`);
                }}
                variant="contained"
              >
                Qidirish
              </Button>
              <SearchedRidePlaces
                openQuickSearchedRides={openQuickSearchedRides}
                type={"couple"}
              />
              {/* <Swipe
                selector={"isSearchedRidesOpen"}
                toggleAction={toggleSearchedRidesSwipe}
                page={RideList}
                closeButtonPos={"bottom"}
                enableScrollToTop={true}
              /> */}
              {/* <SwipeRides  
                                searchDetails={searchDetails} setSearchDetails={setSearchDetails} 
                                handleSubmit={handleSubmit}
                            /> */}
            </div>
          </div>
        </div>
      </FormControl>
    </>
  );
}
export default PassengerBooking;
