import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import httpService from "../../services/http.service";
import { useDispatch } from "react-redux";

const webApp = window.Telegram?.WebApp;
const themeParams = webApp?.themeParams;
const colorScheme = webApp?.colorScheme;
const getDesignTokens = () => ({
  palette: {
    mode: colorScheme,
    primary: {
      main: themeParams?.button_color ?? "#3390ec",
    },
    background: {
      default: themeParams?.bg_color ?? "#ffffff",
      paper: themeParams?.secondary_bg_color ?? "#ffffff",
    },
    text: {
      primary: themeParams?.text_color ?? "#000000",
      secondary: themeParams?.hint_color ?? "#707579",
    },
  },
});
function Layout() {
  const dispatch = useDispatch();
  useEffect(() => {
    const getSearchedRides = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const user_id = parseFloat(params.get("user_id"));
        dispatch({ type: "FETCH_SEARCHED_RIDES_REQUEST" });
        const result = await httpService.getSearchedRides(user_id);
        if (result) {
          const { rides } = result;
          dispatch({ type: "FETCH_SEARCHED_RIDES_SUCCESS", payload: rides });
        }
      } catch (e) {
        dispatch({ type: "FETCH_SEARCHED_RIDES_FAILURE", payload: e.message });
        const params = new URLSearchParams(window.location.search);
        const user_id = parseFloat(params.get("user_id"));
        await httpService.sendToSupport({
          message: "frontend getSearchedRides " + e.message,
          user_id,
          Stack: e.stack ? e.stack : "",
        });
        console.log("Error getting searched rides", e.message);
      }
    };
    getSearchedRides();
  }, [dispatch]);
  const darkModeTheme = createTheme(getDesignTokens());
  return (
    <ThemeProvider theme={darkModeTheme}>
      <div>
        <Outlet />
      </div>
    </ThemeProvider>
  );
}
export default Layout;
