import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import GoogleMap from "../GoogleMap/GoogleMap";
import "./SwipeableMap.css";
import { connect } from "react-redux";
import {
  toggleArrivalMap,
  toggleDepartureMap,
} from "../../redux/actions/mapActions";
const drawerBleeding = 0;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "var(--tg-theme-bg-color)",
}));
const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: "var(--tg-theme-bg-color)s",
  borderRadius: 0,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));
function SwipeableMap({
  initialValue,
  isDepartureOpen,
  isArrivalOpen,
  placeSelected,
  container,
  label,
  toggleArrivalMap,
  toggleDepartureMap,
  swipeType,
}) {
  const [placeValue, setPlaceValue] = React.useState("");
  const placeSelectedSwipe = ({
    formatted_address,
    location,
    area_level_1,
    area_level_2,
  }) => {
    setPlaceValue(formatted_address);
    placeSelected({ formatted_address, location, area_level_1, area_level_2 });
    toggleSwipe();
  };
  const toggleSwipe = () => {
    swipeType === "departure" ? toggleDepartureMap() : toggleArrivalMap();
  };
  useEffect(() => {
    if (Object.keys(initialValue ? initialValue : {}).length !== 0) {
      setPlaceValue(initialValue.formatted_address);
    }
  }, [initialValue]);
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(100% - ${drawerBleeding}px)`,
            overflow: "scroll",
          },
        }}
      />
      <TextField
        disabled={!!Object.keys(initialValue ? initialValue : {}).length}
        value={placeValue}
        InputProps={{ readOnly: true }}
        onClick={() => toggleSwipe()}
        label={label}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={swipeType === "departure" ? isDepartureOpen : isArrivalOpen}
        onClose={() => toggleSwipe()}
        onOpen={() => toggleSwipe()}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <div>
            <GoogleMap
              open={swipeType === "departure" ? isDepartureOpen : isArrivalOpen}
              label={label}
              swipeType={swipeType}
              placeSelected={placeSelectedSwipe}
            />
          </div>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}
SwipeableMap.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const mapStateToProps = (state) => ({
  isDepartureOpen: state.map.isDepartureOpen,
  isArrivalOpen: state.map.isArrivalOpen,
});
export default connect(mapStateToProps, {
  toggleArrivalMap,
  toggleDepartureMap,
})(SwipeableMap);
