import { useEffect, useState } from "react";
import RatingsSection from "../components/RatingsSection/RatingsSection";

const RatingSectionPage = () => {
  const [userId, setUserId] = useState(0);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const user_id = parseFloat(params.get("user_id"));
    if (user_id) setUserId(parseInt(user_id));
  }, []);
  return (
    userId && (
      <div>
        <RatingsSection user_id={userId} userAvatar={null} />
      </div>
    )
  );
};

export default RatingSectionPage;
