import StarRateIcon from '@mui/icons-material/StarRate';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box } from '@mui/material';

export const renderRatingStars = (rating, rating_count, size) => {
    if(rating_count<5){rating = 4}
    let stars = [];
    const fullStars = Math.floor(rating); 
    const hasHalfStar = rating % 1 !== 0; 
    const totalStars = 5;
  
    for (let i = 1; i <= totalStars; i++) {
        if (i <= fullStars) {
            stars.push(<StarRateIcon key={i} style={{ color: '#FFD700', fontSize: `${size}rem` }} />);
        } else if (hasHalfStar && i === fullStars + 1) {
            stars.push(<StarHalfIcon key={i} style={{ color: '#FFD700', fontSize: `${size}rem` }} />);
        } else {
            stars.push(<StarBorderIcon key={i} style={{ fontSize: `${size}rem` }} />);
        }
    }
    return <Box sx={{ display: 'flex', alignItems: 'center' }}>{stars}</Box>;
  };
  