const initialState = {
    isDepartureOpen: false,
    isArrivalOpen: false
}

const mapReducer = (state = initialState, action) => {
    switch(action.type){
        case 'TOGGLE_DEPARTURE_MAP':
            return {
                ...state,
                isDepartureOpen: !state.isDepartureOpen
            };
        case 'TOGGLE_ARRIVAL_MAP':
            return {
                ...state,
                isArrivalOpen: !state.isArrivalOpen
            }
        default:
            return state;
    }
}

export default mapReducer;