const initialState = {
  searchDetails: {
    departurePlace: {},
    departureDateTime: new Date().getTime(),
    frontSeat: false,
    presenceOfWoman: false,
    seats: 1,
    arrivalPlace: {},
    comment: "",
  },
};
const searchDetails = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SEARCH_DETAILS":
      return { ...state, searchDetails: action.payload };
    case "SET_INIT_SEARCH_DETAILS":
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export default searchDetails;
